.skills-container {
  width: 80vw;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    font-size: 3rem;
    line-height: 20px;
  }

  .skills-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 50px;
    grid-gap: 20px;
    @include media(">=phone", "<lgphone") {
      grid-template-columns: 1fr;
    }
    @include media(">=lgphone", "<tablet") {
      grid-template-columns: 1fr;
    }
    @include media(">=tablet", "<desktop") {
      grid-template-columns: 1fr 1fr;
    }
    .skill {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      img {
        height: 8rem;
      }
      p {
        font-size: 14px;
      }
    }
  }
}
