.container {
  margin-bottom: 4vh;
}

.card {
  width: 100%;
  height: 250px;
  overflow: hidden !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.8);
  margin-bottom: 2vh;
  
  .content {
    font-family: "Open Sans";
    text-align: center;
    padding: 10px;
    opacity: 0;
    background-color: black;
    transition: all 500ms ease-in-out;
    transform: translate(0, 20px);
    cursor: pointer;
    h1 {
      font-size: 12px;
      color: white;
    }
    p {
      font-size: 12px;
      color: white;
      margin-bottom: 20px;
    }

    .btn {
      background-color: black;
      color: white;
      padding: 10px;
      text-decoration: none;
    }
    &:hover {
      opacity: 1;
      transform: translate(0, 0px);
    }
  }
}
